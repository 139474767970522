<template>
    <div>
        <section id="wrapper" class="error-page">
            <div class="error-box">
                <div class="error-body text-center">
                    <!-- <h1>503</h1> -->
                    <h3 class="text-uppercase bold mx-3 mt-5">TRANG WEB ĐANG ĐƯỢC BẢO TRÌ, NÂNG CẤP</h3>
                    <h5 class="text-muted my-4">Vui lòng thử lại sau ít phút</h5>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: ""
        }
    },
    computed: {},
    created() {
        let _this = this
        this.timer = setInterval(async function() {
            let data = await _this.$store.dispatch("USER_GET_INFO")
            if (data.status === 200 && data.success) {
                _this.$router.push("/")
            }
        }, 5000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>
<style scoped>
.error-box {
    height: 100%;
    position: fixed;
    background: url(/assets/images/background/error-bg.png) no-repeat center center #fff;
    width: 100%;
    background-size: 60%;
}
.error-box .footer {
    width: 100%;
    left: 0px;
    right: 0px;
}

.error-body {
    padding-top: 5%;
}
.error-body h1 {
    font-size: 10em;
    font-weight: 900;
    text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
    line-height: 210px;
}
</style>
